import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { HeroWithMediaData } from 'components/blocks/HeroBanner';
import HeroWithMedia from 'components/blocks/HeroBanner/HeroWithMedia';
import mapBlocks from 'helpers/blockMappers/mapBlocks';
import { CsBlock } from 'helpers/blockMappers/mappingUtils';
import { trackErrorPageVisit } from 'helpers/eventTracking';

export type ErrorPageProps = {
  data: ErrorPageData;
};

const ErrorPage: React.FC<ErrorPageProps> = ({ data }) => {
  const page = data.csTemplateG;

  useEffect(() => {
    trackErrorPageVisit();
  }, []);

  return (
    <Layout url={page.url} pageMetadata={{ title: page.title }} locale={page.locale}>
      <HeroWithMedia
        hero_with_media={page.hero_with_media}
        showResponsiveVersion={page.show_new_hero}
      />
      {page.blocks && mapBlocks(page.blocks)}
    </Layout>
  );
};

export default ErrorPage;

type ErrorPageData = {
  csTemplateG: {
    title: string;
    show_new_hero: boolean;
    locale: string;
    url: string;
    hero_with_media: HeroWithMediaData;
    blocks: CsBlock[];
  };
};

// Error page was originally called Template G in ContentStack
export const query = graphql`
  fragment ErrorPage on cs__template_g {
    error_code
    title
    locale
    show_new_hero
    hero_with_media {
      ...HeroWithMedia
    }
    blocks {
      action_cards {
        ...ActionCardsBlock
      }
      cta_banners {
        ...CtaBannersBlock
      }
      info_cards {
        ...InfoCardsBlock
      }
      mini_guide {
        ...MiniGuideBlock
      }
      rich_text {
        ...RichTextBlock
      }
    }
  }
`;
