import { graphql } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageProps } from 'templates/ErrorPage';
// Error page was originally called Template G in ContentStack
export const query = graphql`
  query {
    csTemplateG(error_code: { eq: "404" }) {
      ...ErrorPage
    }
  }
`;
const NotFound: React.FC<ErrorPageProps> = ({ data }) => <ErrorPage data={data} />;
export default NotFound;
